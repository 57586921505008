/**
 * 向后端请求用户的菜单，动态生成路由
 */
import { constantRouterMap } from '@/config/router.config'
import { generator } from '@/router/generator-routers'

const permission = {
  state(){
    return{
        routers: [],
        addRouters: []
    }
    
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    }
  },
  actions: {
    GenerateRoutes ({ commit }, data) {
     
      return new Promise((resolve,reject)=>{
        const routers = generator(data)
        if(routers){
          commit('SET_ROUTERS', routers)
          resolve(routers)
        }else{
          commit('SET_ROUTERS', [])
          reject('获取菜单失败')
        }
        
      })
    }
  },
  getters:{
    GET_ROUTERS(state){
        return state.addRouters
    }
  }
}

export default permission
