
import BasicLayout from './basicView.vue'
import PageView from './pageview.vue'
// import { useRoute, useRouter } from "vue-router"
import Vrouter from "@/router"
const route = Vrouter.currentRoute.value
const router = Vrouter
// const { currentRoute } = useRouter();
// const path = currentRoute;
console.log(route);
export {  BasicLayout,PageView}