<!-- 顶部区域 -->
<template>
  <div v-if="laoding">
    <div class="app-header" v-if="!isShow">
      <div class="container-top">
        <h1 class="logo">
          <a class="lin-active"></a>
        </h1>
        <!-- <div class="label-slide" v-for="item in menuList" :key="item.id">
          <img src="../assets/news.png" alt="" class="label-img" />
          <router-link
            class="label-name"         
            :to="item.resPath"
            >{{ item.resName }}</router-link
          >
        </div> -->
        <el-menu
          class="el-menu-demo"
          mode="horizontal"
          :router="true"
          :default-active="tab.activeIndex"
          @select="handleSelect"
          background-color="none"
          text-color="#fff"
          active-text-color="#b61f29"
        >
          <el-menu-item index="/home"> 首页 </el-menu-item>
          <el-menu-item index="/information">党建资讯</el-menu-item>
          <el-menu-item index="/partyStudy">学习教育</el-menu-item>
          <el-menu-item index="/organizeLife">组织生活</el-menu-item>
          <el-menu-item index="/partyService">党建服务</el-menu-item>
          
        </el-menu>
      </div>
      <div class="right" v-if="token">
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ userInfo.realName || "" }} 
            <span class="icon-num" v-if="msgNum">{{msgNum}}</span>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleToCenter">个人中心</el-dropdown-item>
               <el-dropdown-item @click="handleToMsgCenter"><span class="item-link">消息中心<span class="icon-num" v-if="msgNum">{{msgNum}}</span></span></el-dropdown-item>
              <el-dropdown-item @click="handelLoginOut"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div :class="hasHome?'home-contain':'app-contain'">
        <router-view />
    </div>
    <div class="app-footer" v-if="!isShow">
        <p>{{ userInfo.copyright || "智慧数字党建 ©2023 Created by xf" }}</p>
    </div>
    <div class="back-to-ceiling" id="backTop" v-show="isBackTop" @click="handleScrollTop">
      <img src="../assets/up.png" alt="" style="height: 20px;">
    </div>
  </div>
  <div v-else>
    <div class="laoding-box"> 加载中...<el-skeleton :rows="20" animated /></div>
  </div>
</template>
<script setup>
import { useRoute,useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";
import { reactive, ref, watch,onMounted,onUnmounted } from "vue";
const route = useRoute();
const store = useStore();
const router =  useRouter()
let userInfo = reactive({}) 
const isShow = ref(false);
const isBackTop = ref(false)
const scrollTop = ref('') // 距顶部距离
const msgNum = ref(0)
const hasHome = ref(false);
const token = ref(localStorage.getItem('ACCESS_TOKEN'))
let done = false // 是否停止帧函数
let path = ref('')
let laoding = ref(false)
let timer = ref(null)
const tab = reactive({
  activeIndex: localStorage.getItem('activeTabIndex')
})
const handleSelect = (key) => {
  tab.activeIndex = key
}
const getMsgNum = () => {
  clearInterval(timer.value)
  timer.value = null
  timer.value = setImmediate(()=>{
     store.dispatch("GetMsgList").then(()=>{
        msgNum.value= store.getters.list.totalNum>99?99:store.getters.list.totalNum
      }
     )
  },3000)
}
const handelTabIndex = (newPath) => {
  if(flagOf(newPath,"/information")){
    tab.activeIndex = "/information"
  }else if(flagOf(newPath,"/partyStudy")||flagOf(newPath,"/microClass/MICRO_CLASS")||flagOf(newPath,"/microClass/VIDEO")||flagOf(newPath,"/microClass/ORIGINAL_WORK")||flagOf(newPath,"/microClass/SPECIAL_SUBJECT")||flagOf(newPath,"/LearningExperience")||flagOf(newPath,"/exam")){
    tab.activeIndex = "/partyStudy"
  }else if(flagOf(newPath,"/organizeLife")){
    tab.activeIndex = "/organizeLife"
  }else if(flagOf(newPath,"/partyService")||flagOf(newPath,"/partyHistory")||flagOf(newPath,"/orgTransfer")||flagOf(newPath,"/vote")){
    tab.activeIndex = "/partyService"
  }else{
    tab.activeIndex = "/othner"
  }
}
const flagOf = (path,indexPath) => {
  return path.indexOf(indexPath)!==-1
}
watch(
  () => route.path,
  (newPath, oldPath) => {

    if(newPath==='/'){
      laoding.value = false
    }else{
      laoding.value = true
    }
    if(token){
      handelTabIndex(newPath)
    }

    if(JSON.stringify(userInfo) === '{}'){
      userInfo =  JSON.parse(localStorage.getItem('USER_INFO')) || store.getters.userInfo || {}
      token.value = false
    }

    if(newPath.indexOf("/voteDetail")  !== -1){
      path.value  = '/voteDetail'
    }else if(newPath.indexOf("/voteOutUser")  !== -1){
      path.value  = "/voteOutUser"
    }else{
      path.value = newPath
    }

    if(newPath.indexOf("/home")  !== -1 || newPath.indexOf("/login")!== -1 ){
      hasHome.value = true
    }else{
      hasHome.value = false
    }
    isShow.value = newPath === "/login" || path.value  === '/voteDetail' || path.value  === '/voteOutUser' || path.value  === '/home';

    if(!isShow.value && newPath !== '/' && localStorage.getItem('ACCESS_TOKEN')){
      token.value = true
      getMsgNum()
    }

  },
  { immediate: true }
);

// 监听页面滚动展示回到顶部图表
// 设置监听与卸载
onMounted(() => {
	window.addEventListener('scroll', handleScroll)
})
onUnmounted(() => {
	window.removeEventListener('scroll', handleScroll)
})

// 检测距离
function handleScroll() {
	scrollTop.value =
		window.pageYOffset ||
		document.documentElement.scrollTop ||
		document.body.scrollTop
	scrollTop.value > 500 ? (isBackTop.value = true) : (isBackTop.value = false)
}
// 动画帧函数
function step() {
	scrollTop.value <= 0 ? (done = true) : (done = false)
	window.scrollTo({
		top: (scrollTop.value -= 15)
	})
	if (!done) {
		window.requestAnimationFrame(step)
	}
}
// 返回顶部
function handleScrollTop() {
	// 下一次重绘之前更新动画帧所调用的函数 60次1秒
	window.requestAnimationFrame(step)
}

// 跳转到后台管理系统
const handleClick = () => {
  window.location = "http://dj.backend.etl6.cn/";
};
// 跳转到个人中心
const handleToCenter = () =>{
   router.push(`/baseInformation`);
}
// 跳转到消息中心
const handleToMsgCenter = () =>{
   router.push(`/messageCenter`);
}



// 登出
const handelLoginOut = () => {
  ElMessageBox.confirm("确认退出?", "警告", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      store.dispatch("Logout").then((res) => {
        // window.location.reload();
        userInfo = {}
        window.location.href = `#/home`
      });
    })
    .catch(() => {});
};
</script>
<style scoped >
@import "./index.less";
</style>
