<!-- 首页 -->
<template>
  <!--  -->
  <div class="layout-index-outer">
    <div class="layout-header-outer">
      <div class="layout-header-box">
        <img src="../assets/logoBg.png" alt="" />
        <div class="header-login" v-if="!token">
          <router-link :to="denglu">登录</router-link>
        </div>
      </div>
    </div>
    <div class="bulletin-box" v-if="token && state.noticeList.length>0">
      <el-carousel height="50px" direction="vertical" :autoplay="true">
        <el-carousel-item v-for="(item,index) in state.noticeList" :key="index">
          <div class="ok-box" @click="toLinkBulletin(item.id)">
            <img src="../assets/laba.png" class="laba">
            <h3 text="2xl" justify="center">{{ item.title }}</h3>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="layout-content-outer">
      <div class="layout-content-box">
        <div class="layout-top-menu-outer">
          <div style="flex: 1"></div>
          <router-link to="/information">
            <div class="layout-item-box" >
              <div>
                <img src="../assets/zixun.png" alt="" />
                <p>党建资讯</p>
              </div>
            </div>
          </router-link>
          <router-link :to="toLink('/partyStudy')">
            <div class="layout-item-box" >
              <div>
                <img src="../assets/study.png" alt="" />
                <p>学习教育</p>
              </div>
            </div>
          </router-link>
          <router-link :to="toLink('/organizeLife')">
            <div class="layout-item-box" >
              <div>
                <img src="../assets/life.png" alt="" />
                <p><router-link :to="toLink('/organizeLife')">组织生活</router-link></p>
              </div>
            </div>
          </router-link>
          <router-link :to="toLink('/partyService')">
            <div class="layout-item-box" >
              <div>
                <img src="../assets/djfw.png" alt="" />
                <p>党建服务</p>
              </div>
            </div>
          </router-link>
          <div style="flex: 1"></div>
        </div>
      </div>
      <div class="layout-center-menu-outer" style="margin-top:-30px">
        <div class="layout-center-item-box">
          <div class="container">
            <el-carousel height="330px" >
              <el-carousel-item :key="1" class="carousel-item">
                <!-- -->
                <a href="http://dangshi.people.com.cn/GB/441533/index.html" target="_blank">
                  <img src="../assets/1001.jpg" alt="专题｜党史学习教育系列访谈" style="width: 630px; height:330px" />
                </a>
              </el-carousel-item>
              <el-carousel-item :key="2" class="carousel-item">
                <a href="http://cpc.people.com.cn/GB/67481/441273/index.html" target="_blank">
                  <img src="../assets/1002.png" alt="专题｜聚焦十九届六中全会" style="width: 630px; height:330px" />
                </a>
              </el-carousel-item>
              <el-carousel-item :key="3" class="carousel-item">
                <a href="http://dangshi.people.com.cn/GB/440990/index.html" target="_blank">
                  <img src="../assets/1003.png" alt="专题｜“我为群众办实事”经验做法征集" style="width: 630px; height:330px" />
                </a>
              </el-carousel-item>
              <el-carousel-item :key="3" class="carousel-item">
                <a href="http://cpc.people.com.cn/GB/67481/441898/index.html" target="_blank">
                  <img src="../assets/1004.jpg" alt="专题｜奋进新征程 建功新时代" style="width: 630px; height:330px" />
                </a>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="layout-center-item-box layout-center-item-menu-outer">
          <div class="layout-title-box">快捷入口</div>
          <div class="layout-center-menu-list-box">
            <router-link :to="toLink('/messageCenter')">
            <div class="layout-center-item-menu-box">
              <div class="layout-center-item-menu-box">
                <div>
                  <img src="../assets/wyrd.png" alt="" />
                  <p>我的消息</p>
                </div>
              </div>
            </div>
          </router-link>
          <router-link :to="toLink('/vote')">
            <div class="layout-center-item-menu-box">
              <div class="layout-center-item-menu-box">
                <div>
                  <img src="../assets/toupiao.png" alt="" />
                  <p>投票调查</p>
                </div>
              </div>
            </div>
          </router-link>
          <router-link :to="toLink('/payCollection')">
            <div class="layout-center-item-menu-box">
              <div class="layout-center-item-menu-box">
                <div>
                  <img src="../assets/money.png" alt="" />
                  <p>党费缴纳</p>
                </div>
              </div>
            </div>
          </router-link>
            <router-link :to="toLink('/orgTransfer')">
            <div class="layout-center-item-menu-box">
              <div class="layout-center-item-menu-box">
                <div>
                  <img src="../assets/change.png" alt="" />
                  <p>党组织关系转移</p>
                </div>
              </div>
            </div>
          </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  const token = localStorage.getItem('ACCESS_TOKEN')
  import {getAnnouncement} from '@/api/msg'
  import { useRouter } from "vue-router";
  import {onMounted, reactive, ref} from 'vue'
  const router = useRouter();
  const list = router.currentRoute.value
  const denglu = ref('')
  if(list.query.redirect){
    denglu.value = '/login?redirect=' + list.query.redirect
  }else{
    denglu.value = '/login'
  }
  const state = reactive({
    noticeList: []
  })
  onMounted(()=>{
    if(token){
      getAnnouncement().then(res => {
        if(res.data.length>0){
          state.noticeList = res.data
        }else{
          state.noticeList = []
        }  
      })
    }
  })
  const toLinkBulletin = (id) => {
    window.localStorage.setItem('bulletinId', id)
    router.push(`/bulletin`);
  }
  const toLink = (path) => {
    if(token){
      return path
    }else{
      if(list.query.redirect){
        denglu.value = '/login?redirect=' + list.query.redirect
      }else{
        if(path){
          denglu.value = '/login?redirect=' + path
        }else{
          denglu.value ='/login'
        }
      }
      return denglu.value
    }
  }
</script>

<style scoped lang="less">
.layout-index-outer {
  background-color: #f5f5f5;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100%;
  background-image: url("../assets/bage-home.jpg");
  background-size: 100% 100%;
  background-attachment: fixed;
  overflow: hidden;
}

.layout-header-outer {
  background-image: url("../assets/headerMask.png");
  background-size: 100% 100px;
  background-repeat: no-repeat;
  width: 100%;
  height: 80px;
  display: flex;
}
.layout-header-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  img {
    height: 60px;
    margin-left: 40px;
  }
  .header-login {
    color: white;
    font-weight: bold;
    margin-right: 40px;
    cursor: pointer;
    a {
      color: white;
    }
  }
}
.layout-content-outer {
  width: 100%;
  padding-top: 50px;
}
.layout-content-box {
  width: 1200px;
  margin: 0 auto;
}
.layout-top-menu-outer {
  display: flex;
  margin-bottom: 80px;
}
.layout-item-box {
  margin: 0 15px;
  width: 140px;
  height: 125px;
  background-color: #fff;
  opacity: 0.8;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  color: #b61f29;
  cursor: pointer;
  a {
    color: #b61f29;
  }
}
.layout-center-menu-outer {
  width: 1250px;
  margin: 0 auto;
  min-height: 1px;
  display: flex;
  flex-wrap: wrap;
  .layout-center-item-box {
    width: 640px;
    height: 310px;
  }
  .layout-center-item-menu-outer {
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
    width: 540px;
    margin-left: 20px;
    padding: 10px;
    .layout-title-box {
      width: 100%;
      font-size: 18px;
      color: #b61f29;
      margin-bottom: 15px;
    }
    .layout-center-menu-list-box {
      -webkit-box-flex: 1;
      -ms-flex: 1 1;
      flex: 1 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .layout-center-item-menu-box {
      width: 166px;
      height: 115px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      text-align: center;
      font-size: 16px;
      color: #b61f29;
      cursor: pointer;
    }
  }
}
.bulletin-box{
  margin-top: 30px;
  width: 100vw;
  min-height: 1px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.bulletin-box /deep/ .el-carousel{
  width:700px;
  margin-left: 40px;
  border-radius: 20px;
}
.bulletin-box /deep/ .el-carousel__indicator{
  display: none;
}
.bulletin-box /deep/ .el-carousel__item h3 {
  color: #ef0000;
  opacity: 0.6;
  line-height: 50px;
  margin: 0;
}
.bulletin-box /deep/ .ok-box{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.bulletin-box /deep/ .el-carousel__item{
  background-color: #ffffffb9;
}
.bulletin-box /deep/ .laba{
  width:24px;
  height: 24px;
  margin:0 20px;
  opacity: 0.6;
}
</style>

