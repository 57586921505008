// import Vue from 'vue'
// import { createApp } from 'vue'
import axios from 'axios'
import store from '@/store'
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox , ElNotification  } from 'element-plus' /// es/notification
import { VueAxios } from './axios'
// import { ACCESS_TOKEN } from '@/store/mutation-types'
const router = useRouter()
// 创建 axios 实例
const service = axios.create({
//  baseURL: '/api', // api base_url
  timeout: 6000,// 请求超时时间
  headers:{
    'Content-Type':'application/json' //改为json格式
},
})

const err = (error) => {
  console.log(error);
  if (error.response) {
    const data = error.response.data
    const token = localStorage.getItem('ACCESS_TOKEN')

    if (error.response.status === 403) {
      console.log('服务器403啦，要重新登录！')
      ElNotification .error({
        title: 'Forbidden',
        message: data.message
      })
    }
    if (error.response.status === 404) {
      ElMessage.error('资源Not Found！')
    }
    if (error.response.status === 500) {
        ElMessage.error('系统繁忙，稍后再试！')
     
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  if(error.code === "ECONNABORTED"){
    ElMessage.error('系统繁忙，稍后再试！')
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = localStorage.getItem('ACCESS_TOKEN')

  if (token) {
    config.headers["cattoken"] = token;
  }
  return config
}, err)

/**
 * response interceptor
 * 所有请求统一返回
 */
service.interceptors.response.use((response) => {
  if (response.request.responseType === 'blob') {
    return response
  }
  const resData = response.data
  const code = response.data.code

    if (code === 1011006 || code === 1011007 || code === 1011008 || code === 1011009 || code === 401 || code === 403) {
      ElMessageBox.confirm(
        resData.msg,'提示：',
        {
          confirmButtonText: '重新登录',
          type: 'error',
          center: true,
          showCancelButton: false
        }
      ).then(() => {
        localStorage.removeItem('ACCESS_TOKEN')
        window.location.href = `#/home`
      })
    }

  return resData
}, err)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
