import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'// 官网首页

import NotFound from '@/views/error/404.vue'
import Login from '../views/userLogin/index.vue' //登录页

import { useStore } from 'vuex'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
const routes = [
    {
        path: '/',
        name: 'home',
        redirect:"/home",
        children:[{
            path:'/home',
            name:'home',
            component: Home
        }],
        
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/personalCenter',
        name: 'personalCenter',
        component: ()=>import('@/views/personalCenter')
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: NotFound,
        hidden: true
    },
    {
        path: '/voteOutUser/:id',
        name: 'voteOutUser',
        component: () => import('@/views/voteOutUser')
    },
    {
        path: '/information',
        name: 'information',
        component: () => import('@/views/information')
    },

]
const whiteList = ['login', 'register', 'registerResult','voteOutUser','information','home'] // no redirect whitelist
const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    NProgress.start()
    const token = localStorage.getItem('ACCESS_TOKEN')
    const userStore = useStore()
    // 1.判断是访问登陆页，有 Token 就在当前页面，没有 Token 重置路由并放行到登陆页
    // 2.判断访问页面是否在路由白名单(不需要登陆)地址中，如果存在直接放行
    // if (ROUTER_WHITE_LIST.includes(to.path)) return next()

    // 3.判断是否有 Token，没有token跳转到登陆页面并且携带原目标路径
    // 4.如果没有菜单列表，就重新请求菜单列表并添加动态路由
    // 5.上述条件都不满足，直接放行
    if (token) {
        if (to.path === '/login') {
            next(from.fullPath)
            NProgress.done()
        } else {
            if (!userStore.getters.GET_ROUTERS.length) {
                await userStore.dispatch('GetInfo', token).then(async (res) => {
                    await   userStore.dispatch('GenerateRoutes', res.menu).then((list) => {
                        // console.log(list);
                        const routers = userStore.getters.GET_ROUTERS;
                       
                        (routers || []).forEach((route) => {
                            router.addRoute(route);
                        });
                        
                    })
                    const redirect = decodeURIComponent(from.query.redirect || to.path)
                    if (to.path === redirect) {
                        next({ path: redirect })
                        next({ ...to, replace: true })
                    } else {
                        // 跳转到目的路由
                        next({ path: redirect })
                    }
                    
                })
               
            } else {
                next()
            }
        }
    } else {
        if (whiteList.includes(to.name)) {
            // 在免登录白名单，直接进入
            next()
        } else {
            next({ path: '/home', query: { redirect: to.fullPath } })
            NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
        }
    }
})

router.afterEach(() => {
    NProgress.done() // finish progress bar
})

export default router
