
import { login, getUserInfo,logout } from '@/api/system/login'
import { ElNotification } from 'element-plus'

export default {
  namespace: 'true',
  state() {
    return {
      /**
       * user信息
       */
      token: '',
      userInfo: {},
      localMenu: [],
      permission: [],
    }
  },
  mutations: {
    /**
     * 设置user信息
     * @param userInfo
     */
    saveUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    saveToken(state, token) {
      state.token = token;
    },
    saveLocalMenu(state, localMenu) {
      state.localMenu = localMenu;
    },
    savePermission(state, permission) {
      state.permission = permission;
    }
  },
  actions: {
    // 登录
    Login(context,userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          if (response.code !== 200) {
            reject(response.msg)
            return
          }
          const token = response.data.tokenValue
          // Vue.ls.set(ACCESS_TOKEN, result, 7 * 24 * 60 * 60 * 1000)
          localStorage.setItem('ACCESS_TOKEN', token, 7 * 24 * 60 * 60 * 1000)
          context.commit("saveToken", token)
          resolve(token)
          // eslint-disable-next-line handle-callback-err
        }).catch(error => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(error)
        })
      })
    },
    // 获取用户信息
    GetInfo(context,token) {
      return new Promise((resolve, reject) => {
        getUserInfo({ token }).then(res => {
          if (res.code === 200) {
            const { data } = res
            if (!data.menu.length) {
              ElNotification({
                  title: '无权限访问',
                  message: '当前账号无任何菜单权限，请联系系统管理员！',
                  type: 'warning',
                  duration: 3000,
              })
              context.dispatch("Logout").then((res) => {
                window.location.reload();
              });
              reject('No permission')
           }
            context.commit("saveUserInfo", data);
            localStorage.setItem('USER_INFO',JSON.stringify(data) )
            context.commit("saveLocalMenu", data.menu)
            localStorage.setItem('LOCAL_MENU',JSON.stringify(data.menu) )
            context.commit("savePermission", data.permissions)
            localStorage.setItem('PERMISSION',JSON.stringify(data.menu) )
            
            resolve(data)
          } else {
            reject(new Error(res.msg))
          }
        }).catch(error => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(error)
        })
      })

    },
    // 登出
    Logout (context) {
      return new Promise((resolve) => {
        logout().then(() => {
          context.commit('saveUserInfo', {})
          context.commit('saveLocalMenu', [])
          context.commit('savePermission', [])
          context.commit('saveToken', '')
          localStorage.clear()
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
          context.commit('saveUserInfo', {})
          context.commit('saveLocalMenu', [])
          context.commit('savePermission', [])
          context.commit('saveToken', '')
          localStorage.clear()
        })
      })
    },

  },
  getters: {
    userInfo(state) {
      return state.userInfo || JSON.parse(localStorage.getItem('USER_INFO'));
    },
    token(state) {
      return state.token ||  localStorage.getItem('ACCESS_TOKEN');
    },
    localMenu(state) {
      return state.localMenu || JSON.parse(localStorage.getItem('LOCAL_MENU')) ;
    },
    permission(state) {
      return state.permission || JSON.parse(localStorage.getItem('PERMISSION')) ;
    }
  }
}
