import { axios } from '@/http/index'

// 登录
export function login(parameter) {
    return axios({
        url: '/api/custom/auth/login',
        method: 'post',
        data: parameter
    })
}
// 获取用户信息
export function getUserInfo(parameter) {
    return axios({
        url: '/api/get/user',
        method: 'get',
        params: parameter
    })
}
// 登出
export function logout(parameter) {
    return axios({
        url: '/api/logout',
        method: 'post',
        data: parameter
    })
}
// 获取当前登录用户组织信息
export function getLoginUserOrg() {
    return axios({
        url: '/api/custom/user/depart',
        method: 'get',
    })
}