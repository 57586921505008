import { axios } from '@/http/index'
// 获取通知消息的数字统计
export function getAllStatistician(parameter) {
    return axios({
        url: '/api/custom/message/all/statisticians',
        method: 'get',
        params: parameter
    })
}
// 获取首页公告展示
export function getAnnouncement(parameter) {
    return axios({
        url: '/api/sys/announcement/list',
        method: 'post',
        params: parameter
    })
}
