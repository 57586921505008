// eslint-disable-next-line


/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [

   
    {
        path: '/home',
        name: 'home',
        component: () => import( '@/views/Home')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import( '@/views/userLogin')
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import( '@/views/error/404.vue'),
        hidden: true
    },


]
