
import { BasicLayout, PageView } from '@/layout'
// 前端路由表
const constantRouterComponents = {
  // 基础页面 layout 必须引入
  BasicLayout: BasicLayout,
  PageView: PageView,
  // '403': () => import('@/views/error/403'),
  '404': () => import('@/views/error/404'),
  // '500': () => import('@/views/error/500'),

  // 'Workplace': () => import('@/views/system/dashboard/Workplace'),
  'home': () => import('@/views/Home'),
  'voteOutUser': () => import('@/views/voteOutUser')

}
export const generator = (routerMap, parent) => {
  return routerMap.map(item => {
    // eslint-disable-next-line no-unused-vars
    const { title, show, hideChildren, hiddenHeaderContent, target, icon, link } = item.meta || {}
    let fileStr = '';
    if (item.resPath.indexOf("/:id") !== -1) {
        // 包含"/:id"，截取字符串
        const startIndex = item.resPath.indexOf("/:id");
        fileStr = item.resPath.substring(0, startIndex);
      } else {
        // 不包含"/:id"
        fileStr = item.resPath
      }
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path: item.resPath || `${parent && parent.resPath || ''}/${item.key}`,
      // 路由名称，建议唯一
      name: item.resName || item.key || '',
      // 该路由对应页面的 组件 :方案1
      // component: constantRouterComponents[item.component || item.key],
      // 该路由对应页面的 组件 :方案2 (动态加载)
      component: (constantRouterComponents[item.component || item.key]) || (() => import(`@/views${fileStr}`)),
      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: item.resName,
        icon: item.icon || undefined,
        // hiddenHeaderContent: hiddenHeaderContent,
        target: target,
        link: link
      }
    }
    // 是否设置了隐藏菜单
    if (show === false) {
      currentRouter.hidden = true
    }
    // 是否设置了隐藏子菜单
    if (hideChildren) {
      currentRouter.hideChildrenInMenu = true
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/')
    }
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect)
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter)
    }
    return currentRouter
  })
}