<template>
  <!-- <Navbar /> -->
  <el-config-provider :locale="locale">
    <div id="app" class="app app1">
      <PageView   />
    </div>
  </el-config-provider>
</template>
<script setup>
import Navbar from "@/components/Navbar"; // 引入导航栏组件
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import "dayjs/locale/zh-cn";
import { PageView, BasicLayout} from "@/layout";

const locale = zhCn;

</script>

<style lang="scss">
// 全局样式
* {
  margin: 0;
  padding: 0;
}
.app{
  overflow: auto;
}
// 滚动调样式
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
}

::-webkit-scrollbar-thumb {
  background: #1890ff;
}

::-webkit-scrollbar-track {
  background: #ddd;
}
</style>

