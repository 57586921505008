<template>
  <div style="margin: 0 auto">
    <img
      src="../../assets//404.jpg"
      alt="404"
      style="height: 500px; cursor: pointer"
      @click="handleToHome"
    />
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
const handleToHome = () => {
  router.push(`/`);
};
</script>

<style>
</style>