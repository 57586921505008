import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './components/global.less'
import './components/flex.less'
import './components/universal.less'
import ElementPlus from 'element-plus'// 引入element-plus
import 'element-plus/dist/index.css'// 引入组件库的样式
import * as icons from '@element-plus/icons-vue'
const app = createApp(App)
Object.keys(icons).forEach(key => {
    app.component(key, icons[key])
})
app.use(ElementPlus)
    .use(store)
    .use(router)
    .mount('#app')
