import {getAllStatistician} from '@/api/msg'
export default {
  namespace: 'true',
  state() {
    return {
      list: [],
    }
  },
  mutations: {
    saveList(state, list) {
      state.list = list;
    }
  },
  actions: {
    // 获取统计消息
    GetMsgList(context) {
      return new Promise((resolve, reject) => {
        getAllStatistician().then(res => {
          if (res.code === 200) {
            context.commit("saveList", res.data);
            localStorage.setItem('numList',JSON.stringify(res.data))
            resolve(res.data)
          } else {
            reject(new Error(res.msg))
          }
        }).catch(error => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(error)
        })
      })

    }
  },
  getters: {
    list(state) {
      return state.list || JSON.parse(localStorage.getItem('numList')) ;
    }
  }
}
