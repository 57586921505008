<template>
    <div>
      <router-view />
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'BlankLayout'
  }
  </script>
  
  <style scoped>
  
  </style>
  