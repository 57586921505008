<template>
  <div class="layout-login">
    <div class="login-form">
      <div style="width: 100%">
        <h1>智慧党建</h1>
      </div>
      <el-form
        ref="formRef"
        :model="loginParams"
        size="large"
        class="demo-ruleForm"
      >
        <el-form-item
          prop="account"
          :rules="[{ required: true, message: '请输入账号' }]"
        >
          <el-input
            v-model="loginParams.account"
            type="text"
            autocomplete="off"
            placeholder="请输入账号"
            style="width: 300"
            :prefix-icon="User"
          />
        </el-form-item>
        <el-form-item
          prop="password"
          :rules="[{ required: true, message: '请输入密码' }]"
        >
          <el-input
            type="password"
            placeholder="请输入密码"
            v-model="loginParams.password"
            autocomplete="off"
            :prefix-icon="Lock"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm(formRef)"
            style="width: 100%"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { ElMessage ,ElNotification} from "element-plus";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { User ,Lock} from '@element-plus/icons-vue'
const store = useStore();
const router = useRouter();
const formRef = ref({});
const loginParams = reactive({
  account: "",
  password: "",
});
const submitForm = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid, value) => {
    if (valid) {
      store
        .dispatch("Login", { ...loginParams })
        .then((res) => {
          store.dispatch("GetInfo", res).then((data) => {
            if (data) {
              ElNotification({
                title: "登录成功",
                message: "欢迎回来",
                duration: 2000,
                type: 'success',
              });
            }
            let list = router.currentRoute.value
            if(list.query.redirect){
              router.push({ path: list.query.redirect });
            }else{
              router.push({ path: "/home" });
            }
          });
        })
        .catch((error) => {
          ElMessage.error(error);
        });
    } else {
      ElMessage.error("登录失败，请检查填写内容");
      return false;
    }
  });
};

const resetForm = (formEl) => {
  if (!formEl) return;
  formEl.resetFields();
};
</script>

<style scoped lang="scss">
.layout-login {
  height: 100vh;
  width: 100%;
  background: url("../../assets/background2.png");
}
.login-form {
  width: 500px;
  height: 300px;
  text-align: center;
  box-shadow: 0 0 38px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.demo-ruleForm {
  width: 300px;
}
</style>